import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"
class Sodexo extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Sodexo" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-cosmos-cat inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                
                </h2>
            </div>
            <article className="article project inuit-u-p0">
                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="https://mysodexo.ma/" title="" target="_blank">
                                <img src="/img/clients/cosmos-cat.jpg" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">Cosmos Catalogue.</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="#" title="Cosmos Catalogue" target="_blank">Cosmos-Catalogue.ma</a>
                            </span>
                            <span className="project__detail">Year 2020</span>
                        </p>
                    </aside>

                    <p className="v-center">
                        <h1 className="article__heading">Cosmos Catalogue.</h1>
                        <p className="article__para">
                        lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsum
                        </p>
                    </p>

                </div>


                <p className="article__belt" style={{ marginTop: "0" }}>
                    <img className="article__belt-image" src="/img/projects/cosmos-cat/cos-catalog2.jpg" alt="mobile-apps" />
                </p>

                <div className="article__body">
                    <h2 className="article__heading">Le challenge</h2>
                    <p className="article__para">
                    lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsum
                    </p>
                </div>

                <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/cosmos-cat/cos-catalog.jpg" alt="branding" />
                </p>

                {/* <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/mysodexo/uxinfoaffilie.png" alt="branding" />
                </p> */}
                <br /><br />
            </article>
            <Sponsores />
            <WhoAreWe />
        </Layout>
    
  )

}

export default Sodexo
