import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="page-head page-head--no-start-bg page-head--no-scroll-listen">
    <button className="hamburger">
      <span className="hamburger__inner">toggle-menu</span>
    </button>

    <h1 className="page-head__logo">
      <Link to="/" className="page-head__logo-link"></Link>
    </h1>

    <p className="call-to-action">
      <Link getProps={isContactActive} target="_blank" to="/modern-contact/" className="call-to-action__link" title="Contact">Contactez-nous</Link>
    </p>

    <ul className="page-head__list">

        <li className="page-head__list-item">
          <Link
            getProps={isActive}
            to="/"
            className="page-head__link page-head__link--glitch"
            data-glitch-text="Digital"
            title="Digital"
          >
            Digital
          </Link>
        </li>

        <li className="page-head__list-item">
          <Link
            getProps={isActive}
            to="/design/"
            className="page-head__link page-head__link--glitch"
            data-glitch-text="Design"
            title="Design"
          >
            Design
          </Link>
        </li>
        <li className="page-head__list-item">
          <Link
            getProps={isActive}
            to="/web-marketing/"
            className="page-head__link page-head__link--glitch"
            data-glitch-text="Web Marketing"
            title="Web Marketing"
          >
            Web Marketing
          </Link>
        </li>
        <li className="page-head__list-item">
          <Link
            getProps={isActive}
            to="/work/"
            className="page-head__link page-head__link--glitch"
            data-glitch-text="Nos Réalisations"
            title="Nos Réalisations"
          >
            Nos Réalisations
          </Link>
        </li>
        <li className="page-head__list-item">
          
        </li>
    </ul>

    <ul className="keep-up-list keep-up-list--in-nav">
      <li className="keep-up-list__item">
        <a
          title="Check us on Facebook!"
          href="https://www.facebook.com/utopie.maroc/"
          rel="noopener noreferrer"
          target="_blank"
          className="keep-up-list__link keep-up-list__link--facebook">
          <span className="keep-up-list__message">Utopie on Facebook</span></a>
      </li>
      <li className="keep-up-list__item">
        <a
          title="Check us on Twitter!"
          href="https://twitter.com/Utopie_Maroc"
          rel="noopener noreferrer"
          target="_blank"
          className="keep-up-list__link keep-up-list__link--twitter"
        >
          <span className="keep-up-list__message">Utopie on Twitter</span>
        </a>
      </li>
      <li className="keep-up-list__item">
        <a
          title="Check us on Instagram!"
          href="https://www.linkedin.com/company/utopiemaroc/"
          rel="noopener noreferrer"
          target="_blank"
          className="keep-up-list__link keep-up-list__link--instagram"
        >
          <span className="keep-up-list__message">Utopie on LinkedIn</span>
        </a>
      </li>
    </ul>
  </header>
)

const isActive = ({ isCurrent }) => {
  return isCurrent ? { style: { textDecoration: "underline" } } : null
  // return isCurrent ? { style: { borderBottom: "2px solid #fff" } } : null
}

const isContactActive = ({ isCurrent }) => {
  return isCurrent ? { style: { backgroundColor: "#ff1c87" } } : null
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
